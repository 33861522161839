import React, { CSSProperties, useContext } from "react"
import { AppDataContext, FileEpub } from "../AppDataStore"
import { timeAgo, slugify } from "../utils"
import { Link } from "gatsby"
// prettier-ignore
import {
    Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
    Opener
} from "../Reusable";
const IconButton = require("@material-ui/core/IconButton").default
const ClearIcon = require("@material-ui/icons/Clear").default
const OpenInNewIcon = require("@material-ui/icons/OpenInNew").default
const FileCopyIcon = require("@material-ui/icons/FileCopy").default
const LinearProgress = require("@material-ui/core/LinearProgress").default
const Snackbar = require("@material-ui/core/Snackbar").default

const colors = {
  blue: "#4299E1",
  blueItemBackground: "#EDF2F7",
}
import { jdump } from "../utils"

const Item = ({
  item,
  onRemove,
  ...bprops
}: { item: FileEpub; onRemove } & BoxProps) => {
  return (
    <Box {...bprops}>
      <Boxf justify="space-between" bgcolor={colors.blueItemBackground}>
        <Boxf align="center">
          <Box
            bgcolor="#2D3748"
            height={150}
            width={100}
          >
            {item.imageB64 && <img src={item.imageB64}
            height={150}
            width={100}

            />}

          </Box>
          <Boxfc ml={2}>
            <Boxf align="center">
              <H4 mr={0.5}>
                {item.name}
                <IconButton size="small" onClick={onRemove}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </H4>
            </Boxf>
            {/*<Txt>page count...</Txt>*/}
          </Boxfc>
        </Boxf>
        <Boxf align="center" pr={2}>
          <Opener onClose={async () => {}}>
            {(snackbar) => (
              <>
                {(() => {
                  switch (item.status) {
                    case "loading":
                      return (
                        <Boxfc>
                          <Txt>parsing data...</Txt>
                          <LinearProgress style={{ width: "140px" }} />
                        </Boxfc>
                      )
                    case "ok":
                      return (
                        <Box>
                            <Link to={`/file/${item.slug}`}>
                          <IconButton size="medium">
                            <OpenInNewIcon fontSize="large" />
                          </IconButton>
                          </Link>
                        </Box>
                      )
                  }
                })()}
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={snackbar.isOpen}
                  onClose={snackbar.handleClose}
                  message="Link copied!"
                />
              </>
            )}
          </Opener>
        </Boxf>
      </Boxf>
    </Box>
  )
}

export const ListEpub = ({}: {}) => {
  const { files, removeFile } = useContext(AppDataContext)
  return (
    <Boxfc>
      {files.length == 0 && <i>no epubs, please upload one</i>}
      {files.map((file) => (
          <Item key={file.id} item={file} onRemove={() => removeFile(file)} mb={1}/>
      ))}
    </Boxfc>
  )
}
