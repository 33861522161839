import React, { useContext, useState } from "react"
import { Router } from "@reach/router"
import { navigate, Link } from "gatsby"
import "../global.css"
import { AppDataContext, AppDataStore } from "../AppDataStore"
import {
  Boxf as Cols,
  Boxfc as Rows,
  Box,
  BoxCont,
  H4,
  H1,
  H3,
  Txt,
  Button,
} from "../Reusable"
import { jdump } from "../utils"
import { DragAndDropZone } from "../components/DragAndDropZone"
import { ListEpub } from "../components/ListEpub"
const IconButton = require("@material-ui/core/IconButton").default
const ClearIcon = require("@material-ui/icons/Clear").default
const FileCopyIcon = require("@material-ui/icons/FileCopy").default

const Page = ({}: {}) => {
  return (
    <AppDataStore>
        <Rows>
        <DragAndDropZone type="clipboard"/>
        <DragAndDropZone type="epub"/>

        <ListEpub />

        </Rows>
    </AppDataStore>
  )
}

export default Page
