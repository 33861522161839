import React, { CSSProperties, useEffect, useCallback, useContext } from "react"
import { useDropzone } from "react-dropzone"
// prettier-ignore
import {
    Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
} from "../Reusable";
import { print } from "../utils"
import { AppDataContext } from "../AppDataStore"

const ImageIcon = require("@material-ui/icons/Image").default

function getFile(file) {
  return new Promise((resolve) => {
    //if (!file.type.startsWith("application/epub+zip")) throw `incorrect image`
    var reader = new FileReader()
    //zip.TextReader(text)
    resolve(file)
    /*reader.onload = function (event) {
      const b64Image = event.target?.result
      if (!b64Image) throw `incorrect image`
      const img =
        typeof b64Image == "string"
          ? b64Image
          : new TextDecoder("utf-8").decode(b64Image)
      resolve(img.split(",")[1])
    }
    reader.readAsDataURL(file)*/
  })
}

export const DragAndDropZone = ({
  disabled,
  type,
}: {
  disabled?: boolean
  type: "epub" | "clipboard"
}) => {
  const { addFile, addClipboard } = useContext(AppDataContext)
  const add = (b,name) => {
    if (type == "epub") addFile(b,name)
    else addClipboard(b,name)
  }
  useEffect(() => {
    document.onpaste = async function (event) {
      if (!event || !event.clipboardData) return
      var items = event.clipboardData.items
      console.log(JSON.stringify(items)) // will give you the mime types
      for (let index in items) {
        var item = items[index]
        if (item.kind === "file") {
          var blob = item.getAsFile()
           if (!blob) continue
        add(getFile(blob),blob.name)
}
      }
    }
  }, [])

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    console.log("files", acceptedFiles)
    // Do something with the files
    for (let file of acceptedFiles) {
      add(getFile(file), file.name)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...(type == "epub" && {accept:".epub"} || {})
  })
  return (
    <div
      {...getRootProps({
        onClick: (event) => event.stopPropagation(),
      })}
      style={disabled ? { opacity: 0.3, pointerEvents: "none" } : {}}
    >
      <Boxfc
        align="center"
        justify="center"
        border="8px dashed"
        borderColor="primary.main"
        minHeight="200px"
        bgcolor="background.default"
        borderRadius="20px"
      >
        <ImageIcon size="large" style={{ fontSize: "6em" }} color="primary" />
        <H1>Drag & Drop</H1>
        <Txt>
          your {type} here, or{" "}
          <span
            {...getRootProps()}
            style={{
              textDecoration: "underline",
              fontStyle: "italic",
            }}
          >
            browse
            <input {...getInputProps()} />
          </span>
        </Txt>
      </Boxfc>
    </div>
  )
}
